import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Layout from '../../layout/Layout';
import FunctionalityAndCasesContainer from '../../components/services/FunctionalityAndCasesContainer';
import ServiceIntroduction from '../../components/services/ServiceIntroduction';
import HomeConsultation from '../../components/home/HomeConsultation';
import PriceContainer from '../../components/services/PriceContainer';
import ObjectStoragePriceDisplay from '../../components/pricing/ObjectStoragePriceDisplay';
import RelatedGuidesContainer from '../../components/services/RelatedGuidesContainer';
import colors from '../../theme/colors';

const infoWrapper = {
    maxWidth: '1440px',
    width: '100%',
    padding: '40px 136px',
    minHeight: '406px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
};

const serviceIntroductionData = {
    title: 'Object Storage Service',
    firstParagraph: 'Almacenamiento de objetos compatible con S3.',
    secondParagraph: 'Guardá tus datos no estructurados en un espacio seguro, escalable a demanda y accesible desde cualquier lado.',
    gatsbyImg: (<StaticImage src="../../images/bucket.svg" alt="Almacenamiento" />),
    isButton: true,
    buttonText: 'Crear ahora',
    buttonLinkTo: 'https://portal.nubi2go.com/index.php?rp=/store/object-storage-service',
};

const casesOfUseData = {
    title: 'Casos de uso',
    cases: [
        {
            image: (<StaticImage src="../../images/snapshot_small.svg" alt="Backup" />),
            text: 'Backup y archiving',
        },
        {
            image: (<StaticImage src="../../images/hot_volumes.svg" alt="Contenido de aplicaciones" />),
            text: 'Contenido de aplicaciones web y mobile',
        },
        {
            image: (<StaticImage src="../../images/instance_connection.svg" alt="Datos no estructurados" />),
            text: 'Datos no estructurados para análisis',
        },
    ],
    gridColumns: 12,
    maxRowGridSize: 4,
    customPadding: '50px 136px 0px',
};

const relatedGuidesData = {
    guides: [
        {text:'Cómo usar la API', link: "https://nubi2go.com/docs/object_storage_service/#instalar-el-aws-cli"},
        {text:'Ejemplo de librería', link: "https://nubi2go.com/docs/object_storage_service/#integraciones-en-el-codigo"}
    ],
    servicesData: [
        'Accesible por S3 API y lenguajes/frameworks.',
        'Organizar tus datos de manera sencilla.',
        'Replicación de datos 3 a 1.',
    ],
    customVioletContainerProp: {
        minHeight: '258px',
    },
};

const priceContainerData = {
    title: 'Conocé tu precio',
    text: (
        <ObjectStoragePriceDisplay />
    ),
};

const ObjectStorage = () => (
    <Layout>
        <ServiceIntroduction serviceIntroductionData={serviceIntroductionData} />

        <FunctionalityAndCasesContainer casesOfUseData={casesOfUseData} />

        <Box
            style={{
                backgroundColor: colors.darkViolet,
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Box sx={(theme) => ({ [theme.breakpoints.down('md')]: { padding: '0px 24px 48px' }, ...infoWrapper })}>
                <RelatedGuidesContainer relatedGuidesData={relatedGuidesData} />
            </Box>
        </Box>

        <PriceContainer priceContainerData={priceContainerData} />
        <HomeConsultation />
    </Layout>
);

export default ObjectStorage;
