import React, { useState, useEffect } from 'react';
import {
    TextField, Box, Typography, FormControl, InputLabel,
} from '@mui/material';
import colors from '../../theme/colors';

const customTextContainer = {
    display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline',
};
const customTextField = {
    fontSize: 50,
    fontWeight: 600,
    height: '50px',
    fontFamily: 'Montserrat',
    color: colors.cyan,
};
const customTypography = {
    fontWeight: 700,
    color: colors.cyan,
    fontSize: '62px',
    '@media (max-width: 680px)': {
        fontSize: '32px',
    },
};

const MAX_INPUT_QUANTITY = 6;

const ObjectStoragePriceDisplay = () => {
    const [objectStorageServiceQuantity, setObjectStorageServiceQuantity] = useState(4);
    const handleObjectStorageServicePrice = (storageSize) => {
        let price = 0;
        if ((storageSize * 0.1) % 1 === 0) price = parseInt(storageSize * 0.1, 10);
        else price = Number(storageSize * 0.1).toFixed(1);
        return price;
    };
    const [objectStorageServicePrice, setObjectStorageServicePrice] = useState(
        handleObjectStorageServicePrice(objectStorageServiceQuantity),
    );

    const handleInputWidth = () => {
        const quantityOfNumberOnes = objectStorageServiceQuantity.toString().replace(/[^'1']/g, '').length;
        const numberOfPixelsPerCharacter = 34;
        if (objectStorageServiceQuantity.length > 1) {
            let inputLength = objectStorageServiceQuantity.length;
            if (objectStorageServiceQuantity.length > 6) inputLength = MAX_INPUT_QUANTITY;
            return { width: `${(numberOfPixelsPerCharacter * inputLength) - (14 * quantityOfNumberOnes)}px` };
        }
        return { width: `${numberOfPixelsPerCharacter * 1}px ` };
    };

    useEffect(() => {
        setObjectStorageServicePrice(handleObjectStorageServicePrice(objectStorageServiceQuantity));
    }, [objectStorageServiceQuantity]);

    return (
        <Box sx={{ ...customTextContainer }}>
            <Typography variant="sectionH2" style={{ fontWeight: 500 }}>
                El precio por&nbsp;
                <FormControl>
                    <InputLabel htmlFor="gbQuantityInput" style={{ color: 'transparent' }}>
                        Cantidad de GB
                    </InputLabel>
                    <TextField
                        id="gbQuantityInput"
                        aria-labelledby="Cantidad de GB"
                        aria-label="Cantidad de GB"
                        value={objectStorageServiceQuantity}
                        variant="standard"
                        type="number"
                        onKeyDown={(e) => (
                            e.key === 'e'
                            || e.key === '-'
                            || e.key === '+'
                            || e.key === ','
                            || e.key === '.'
                        )
                        && e.preventDefault()}
                        InputProps={{
                            sx: customTextField,
                            'aria-label': 'Cantidad de GB',
                        }}
                        sx={{
                            borderBottom: `5px solid ${colors.cyan}`,
                            'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
                                WebkitAppearance: 'none',
                                margin: 0,
                            },
                        }}
                        style={handleInputWidth()}
                        onChange={(e) => {
                            const { value } = e.target;
                            if (!(Number.isNaN(value))
                            && value >= 0
                            && value <= 9999) {
                                setObjectStorageServiceQuantity(value);
                            }
                        }}
                    />
                </FormControl>

                &nbsp;GB es de&nbsp;
                <Typography
                    variant="sectionH2"
                    sx={{ ...customTypography }}
                >
                    USD&nbsp;
                    {objectStorageServicePrice}
                </Typography>
                /MES
            </Typography>
        </Box>
    );
};

export default ObjectStoragePriceDisplay;
