import React from 'react';
import {
    Box, Typography,
} from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import colors from '../../theme/colors';

const priceWrapper = {
    backgroundColor: colors.burgundy,
    boxShadow: 'inset 0px 5px 20px 2px rgba(0, 0, 0, 0.15)',
};
const dollarsMask = {
    width: '100%',
    height: '100%',
};
const priceContainer = {
    minHeight: '344px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: '40px 0px',
    textAlign: 'center',
};

const PriceContainer = ({ priceContainerData }) => (
    <Box sx={{ ...priceWrapper }}>
        <Box sx={{ ...dollarsMask }}>
            <Box sx={{ ...priceContainer, ...priceContainerData.customContainerProp }}>
                <StaticImage src="../../images/giant-dollar-sign.svg" alt="Dolar" />
                <Typography variant="sectionH2" pt={2} sx={{ ...priceContainerData.customTextProp }}>
                    {priceContainerData.title}
                </Typography>
                {priceContainerData.text}
            </Box>
        </Box>
    </Box>
);

export default PriceContainer;
