import React from 'react';
import {
    Grid, Box, Typography,
} from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { StaticImage } from 'gatsby-plugin-image';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import { Link } from 'gatsby';
import colors from '../../theme/colors';

const guidesWrapper = {
    background: `linear-gradient(180deg, #26192C -31.93%, ${colors.violet} 54.16%)`,
    border: '2px solid transparent',
    height: '100%',
    minHeight: '219px',
    borderRadius: '10px',
};
const centeredFlexContainer = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
};
const guidesContainer = {
    display: 'flex',
    textAlign: 'left',
    alignItems: 'start',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    padding: '0px 0px 0px 30px',
};

const customTextTypography = {
    fontFamily: 'Nunito',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '39px',
    letterSpacing: '0em',
    textAlign: 'left',
};
const arrowIcon = {
    color: colors.green,
    fontSize: 'medium',
    marginLeft: '8px',
    fontWeight: 'light',
};
const customCircleRoundedIcon = {
    color: colors.lightGreen,
    fontSize: 'small',
    transform: 'scale(0.75)',
};

const RelatedGuidesContainer = ({ relatedGuidesData }) => (
    <Box mt={2}>
        <Grid container>
            <Grid
                item
                lg={7}
                xs={12}
                sx={(theme) => ({ [theme.breakpoints.down('md')]: { paddingBottom: '20px' }, ...guidesWrapper })}
            >
                <Grid
                    container
                    sx={{ minHeight: '219px' }}
                    style={{ ...relatedGuidesData.customVioletContainerProp }}
                >
                    <Grid
                        item
                        md={7}
                        xs={12}
                        sx={(theme) => ({ [theme.breakpoints.down('md')]: { padding: '14px' }, ...guidesContainer })}
                    >
                        <Typography variant="subSectionH2">
                            Guias relacionadas
                        </Typography>
                        {relatedGuidesData.guides.map((guide) => (
                            <Box
                                key={guide.text}
                                component={Link}
                                to={guide.link}
                                style={{ textDecoration: 'none', color: colors.lightGreen }}
                            >
                                <Typography sx={{ ...customTextTypography }}>
                                    {guide.text}
                                    <DoubleArrowIcon sx={{ ...arrowIcon }} />
                                </Typography>
                            </Box>
                        ))}
                    </Grid>
                    <Grid item md={5} xs={12} pr={1} sx={{ ...centeredFlexContainer }}>
                        <StaticImage src="../../images/book_153.svg" alt="Libro" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                lg={5}
                xs={12}
                sx={(theme) => ({ [theme.breakpoints.down('lg')]: { paddingTop: '32px' }, ...centeredFlexContainer })}
            >
                <Box style={{ textAlign: 'left', maxWidth: '347px' }}>
                    <Box style={{ textAlign: 'left', maxWidth: '347px', width: '100%' }} pb={2}>
                        <Typography variant="subSectionH2">
                            Datos del servicio
                        </Typography>
                    </Box>
                    {relatedGuidesData.servicesData.map((service) => (
                        <Grid container columns={24} key={service}>
                            <Grid
                                item
                                xs={2}
                                style={{ paddingTop: '9px' }}
                            >
                                <CircleRoundedIcon style={{ ...customCircleRoundedIcon }} />
                            </Grid>
                            <Grid item xs={22}>
                                <Typography variant="h5" sx={{ ...customTextTypography }}>
                                    {service}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            </Grid>
        </Grid>
    </Box>
);

export default RelatedGuidesContainer;
